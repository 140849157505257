.contact-page {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 60px 0 40px;
  font-style: normal;
  font-weight: bold;

  .thank-you {
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    color: $color-v2-orange;
  }

  h1 {
    font-weight: bold;
    font-size: 42px;
    line-height: 25px;
    text-align: center;
    color: $color-v2-orange;
    margin-bottom: 40px;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .contact-form {
      width: 100%;
      max-width: 350px;
      margin-right: 50px;
      text-align: center;

      .input-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;

        label {
          width: fit-content;
          height: 20px;
          padding: 0;
          font-size: 16px;
          line-height: 1.1;
          background: #fff;
          color: #000;
          border: none;
        }

        input {
          color: #000;
          width: 100%;
          height: 100%;
          max-height: 30px;
          border: 1px solid transparent;
          border-bottom: 1px solid $color-v2-orange;

          &:focus {
            outline: none;
          }
        }

        textarea {
          width: 100%;
          resize: none;
          border: 1px solid $color-v2-orange;
          border-radius: 8px;
          padding: 5px 10px;

          &:focus {
            outline: none;
          }
        }
      }

      .btn-send {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    img {
      width: 100%;
      max-width: 227px;
      height: auto;
    }
  }

  @include md {
    padding: 40px 0 180px;

    .content {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .contact-form {
        margin: 0 0 30px 0;
        padding: 0 10px;
      }
    }
  }
}
