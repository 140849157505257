.constructor-container .container-fluid {
  padding-right: 10px;
  padding-left: 10px;
}

.constructor {
  width: 100%;
  max-width: 800px;
  min-height: 130vh;
  font-family: 'Arimo', sans-serif;

  .form-control,
  textarea {
    &::placeholder {
      color: hsl(0, 0%, 80%);
    }

    &::-webkit-input-placeholder {
      color: hsl(0, 0%, 80%);
    }

    &:-ms-input-placeholder {
      color: hsl(0, 0%, 80%);
    }
  }

  .form-control,
  .input-group-text {
    height: 38px;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
    padding: 4px 8px;

    @include lg {
      height: 34px;
      font-size: 14px;
    }

    @include md {
      height: 30px;
    }
  }

  .indicator-panel {
    width: 100%;
    margin: 20px 0;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #55595c;

    @include lg {
      margin: 10px 0;
    }

    .alias-url-link {
      color: $color-gray-dark;
      font-size: 24px;
      min-height: 38px;
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }

    .alias-url-string {
      font-size: 24px;
      color: #b6b5b5;
      margin: 0;
      word-break: break-all;

      span {
        height: 100%;
        width: 100%;
      }
    }

    .start-tour {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      max-height: 24px;
      width: 100%;
      max-width: 24px;
      cursor: pointer;

      span {
        border-radius: 12px;
        display: block;
        height: 24px;
        width: 24px;
      }
    }
  }

  .title {
    margin: 30px 0 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @include sm {
      flex-direction: column;

      &-inner {
        width: 100%;

        .expand-rollup-block {
          justify-content: space-between;
          width: 100%;
        }
      }
    }

    h2 {
      color: #304149;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 100%;
      margin: 0;

      @include md {
        font-size: 30px;
      }

      span {
        display: block;
        width: 230px;
      }
    }

    &-inner {
      display: flex;
      align-items: center;

      .expand-rollup-block {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
  }

  .beauty-container {
    background: $color-gray;
    padding: 10px;
    position: relative;
    margin-left: -10px;
    margin-right: -10px;

    h5 {
      font-size: 14px;
      line-height: 125%;
      letter-spacing: -0.3px;
      margin-bottom: 20px;
    }

    .color-pickers {
      margin-left: -10px;
      margin-right: -10px;

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        max-width: 50%;
        min-width: 300px;
        z-index: 11;

        label {
          align-items: center;
          border: 1px solid $color-border-main;
          box-sizing: border-box;
          border-radius: 4px 0 0 4px;
          display: flex;
          flex: 1;

          .icon {
            bottom: 10px;
            left: 10px;
            position: relative;
          }
        }

        input {
          width: 38px;
          border-left: none;
          padding: 0;
          border-radius: 0 4px 4px 0;

          @include lg {
            width: 34px;
          }

          @include md {
            width: 30px;
          }

          &::-webkit-color-swatch-wrapper {
            padding: 4px;
          }

          &::-webkit-color-swatch {
            border: none;
            border-radius: 4px;
          }
        }

        .skeleton-container {
          height: 100%;
          width: 100%;

          span {
            display: block;
            height: 38px;
            width: 100%;

            @include lg {
              height: 34px;
            }

            @include md {
              height: 30px;
            }
          }
        }
      }
    }

    .custom-logo-input {
      display: flex;
      align-items: center;
      margin: 10px 0 30px;
      gap: 20px;

      .skeleton-button {
        width: 150px;

        @include lg {
          width: 130px;
        }

        @include md {
          width: 120px;
        }
      }
    }

    .socials {
      display: flex;
      flex-direction: column;

      &-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 25px !important;
      }

      &-item {
        margin: 0 -10px 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &-inner {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;

          label {
            width: 38px;
            border-radius: 4px 0 0 4px;
            border-color: $color-border-main;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            @include lg {
              width: 34px;
            }

            @include md {
              width: 30px;
            }
          }

          .input-group {
            flex-wrap: nowrap;
            flex: 1;

            input {
              border-left: none;
              border-radius: 0 4px 4px 0;
            }

            span {
              display: block;
              height: 38px;
              width: 100%;

              @include lg {
                height: 34px;
              }

              @include md {
                height: 30px;
              }
            }
          }
        }
      }
    }
  }

  .lang-choose {
    .languages-list {
      @include lg {
        padding-left: 0;
        padding-right: 0;
      }

      .language-toggle {
        max-width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        gap: 20px;

        .default-language {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          margin: 0;

          h5 {
            font-size: 16px;
            line-height: 150%;
            color: #55595c;
            margin-bottom: 0;
            margin-right: 10px;
            height: min-content;

            span {
              width: 120px;
            }
          }
        }
      }
    }

    .remove-lang-container {
      display: flex;
      justify-content: flex-start;
      margin: 0;
      margin-top: 20px;
      padding: 0;
    }

    .add-lang-container {
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;

      @include lg {
        padding-left: 0;
        padding-right: 0;
      }

      .input-group {
        flex-wrap: nowrap;

        .select-container {
          max-width: 200px;

          .select-container__control {
            border-radius: 0 4px 4px 0;
          }
        }

        .skeleton-container {
          height: 38px;
          width: 340px;

          @include lg {
            height: 34px;
          }

          @include md {
            height: 30px;
          }

          span {
            display: block;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .content-container {
    .measure-title-container {
      margin-left: 0;
      margin-right: 0;

      .measure-title {
        padding-left: 0;
        padding-right: 0;

        h5 {
          font-size: 14px;
          line-height: 125%;
          letter-spacing: -0.3px;
          margin-bottom: 20px;
        }
      }
    }

    .measure-units {
      margin-left: 0;
      margin-right: 0;
      gap: 20px;

      .unit {
        margin-bottom: 0;
        max-width: 200px;
        padding-left: 0;
        padding-right: 0;

        .input-group {
          flex-wrap: nowrap;

          .skeleton-container {
            height: 38px;
            width: 100%;

            @include lg {
              height: 34px;
            }

            @include md {
              height: 30px;
            }

            span {
              display: block;
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    .wifi-input-container {
      margin-left: 0;
      margin-right: 0;
      gap: 20px;
      max-width: 500px;
      width: 100%;

      .wifi-input {
        margin-bottom: 0;
        max-width: 200px;
        padding-left: 0;
        padding-right: 0;

        .input-group {
          flex-wrap: nowrap;

          .skeleton-container {
            height: 38px;
            width: 100%;

            @include lg {
              height: 34px;
            }

            @include md {
              height: 30px;
            }

            span {
              display: block;
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .rest-name-container {
    margin-left: auto;
    margin-right: auto;

    .rest-name {
      padding-left: 0;
      padding-right: 0;

      .input-group {
        flex-wrap: nowrap;

        .skeleton-container {
          height: 38px;
          width: 100%;

          @include lg {
            height: 34px;
          }

          @include md {
            height: 30px;
          }

          span {
            display: block;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .units-alert {
    &-show {
      transition: all 0.5s ease-in-out;
      overflow: hidden;
      opacity: 1;
      margin: 5px 0 0 0;
      padding: 10px 20px;
      height: 110px;

      p {
        height: 100%;
        width: 100%;
        transition: all 0.5s ease-in-out;
        opacity: 1;
      }

      &.currency-alert {
        height: 200px;
      }
    }

    &-hide {
      padding: 0 10px;
      margin: 5px 0 0 0;
      opacity: 0;
      height: 0;
      transition: all 0.5s ease-in-out;
      overflow: hidden;

      p {
        height: 100%;
        width: 100%;
        transition: all 0.5s ease-in-out;
        opacity: 0;
      }

      &.currency-alert {
        height: 0;
      }
    }
  }
}
