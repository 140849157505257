@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@700;800&family=Noto+Sans:wght@400;700&display=swap');

.v2-home-page-container {
  width: 100%;
  min-height: calc(100vh - 240px);
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Noto sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;

  p {
    font-family: 'Noto sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 800;
    font-style: normal;
    margin: 0;
    padding: 0;
  }

  h3 {
    width: 100%;
    font-style: italic;
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    margin: 40px 0;
  }

  .v2-section {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner {
      width: 100%;
      max-width: 1150px;
      padding: 0 20px;

      @include md {
        max-width: 400px;
        padding: 0 10px;

        h3 {
          font-size: 28px;
          line-height: 34px;
        }
      }
    }
  }

  .cta-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;

    .cta-offer {
      img {
        margin-right: 10px;
      }
    }

    @include md {
      .cta-offer {
        margin-top: 10px;
        font-size: 13px;

        img {
          margin-left: 0 !important;
        }
      }
    }
  }

  .v2-hero {
    overflow: hidden;
    top: -90px;
    padding: 90px 0 70px;

    .big-background-shape-container {
      position: absolute;
      width: 100%;
      min-height: max-content;
      height: 100%;
      max-height: 640px;
      padding-left: calc(50% - 80px);
      top: 0;
      z-index: -1;
      left: 0;

      .background-blob {
        width: 100%;
        height: 100%;
        min-width: 900px;
        min-height: 700px;
        position: relative;
      }
    }

    .hero-image-container {
      position: absolute;
      width: 100%;
      top: 90px;
      left: 50%;
      padding-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .hero-image {
        width: 655px;
      }
    }

    .inner {
      height: 100%;
      min-height: max-content;
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      z-index: 6;

      .content-container {
        width: 100%;
        max-width: 40%;
        min-width: 470px;
        padding: 50px 0 100px;

        .early-acces {
          width: fit-content;
          height: 30px;
          background: #fff2e8;
          color: #ff7a45;
          border-radius: 6px;
          padding: 4px 8px;
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: 700;
          text-transform: capitalize;
        }

        h1 {
          color: #262626;
          font-family: 'Fira sans', sans-serif;
          font-size: 64px;
          font-weight: 800;
          font-style: italic;
          line-height: 76.8px;
          margin: 0;
          margin-bottom: 25px;
        }

        .subtitle {
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          margin-bottom: 16px;
        }

        ul {
          width: 100%;
          margin-bottom: 60px;

          li {
            width: 100%;
            position: relative;
            padding-left: 20px;
            line-height: 24px;

            &::before {
              content: '';
              position: absolute;
              width: 4px;
              height: 4px;
              border-radius: 100%;
              top: 10px;
              left: 10px;
              background-color: #000;
            }
          }
        }

        .cta-container {
          .v2-button {
            width: 145px;
            padding: 8px;
            text-align: center;
          }

          .cta-offer {
            img {
              margin-left: 10px;
            }
          }
        }
      }

      @include md {
        flex-direction: column;
        align-items: center;

        .content-container {
          padding: 0;
          max-width: 350px;
          min-width: auto;

          h1 {
            font-size: 48px;
            line-height: 58px;
          }

          .early-acces {
            font-size: 12px;
            line-height: 20px;
          }

          .subtitle {
            font-size: 16px;
            line-height: 24px;
          }

          ul {
            margin-bottom: 30px;

            li {
              font-size: 14px;
              line-height: 22px;
            }
          }

          .cta-container {
            flex-direction: column;
            justify-content: space-between;
          }
        }

        .mobile-wallpapers {
          position: relative;
          margin-top: 50px;
          left: -30px;
          height: 100%;
          width: 120%;
          display: flex;
          flex-direction: row;
          justify-content: center;

          img {
            max-width: 550px;
          }
        }
      }
    }

    @include md {
      padding: 0;
      top: 0;
    }
  }

  .v2-how-it-works {
    .inner {
      padding-bottom: 90px;

      h3 {
        margin-top: 0;
      }

      .items-list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 30px;

        .item {
          width: 100%;
          min-height: 172px;
          background: #fff;
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          padding: 15px;

          .icon-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            margin: 10px;

            h4 {
              color: #ff7a45;
            }
          }
        }
      }

      @include md {
        align-items: center;
        padding-bottom: 50px;

        h3 {
          margin-top: 40px;
        }

        .items-list {
          flex-direction: column;
          align-items: center;

          .item {
            max-width: 350px;
          }
        }
      }

      @include lg {
        .items-list {
          // flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-flow: row wrap;

          .item {
            max-width: 350px;
          }
        }
      }
    }
  }

  .v2-gallery {
    .inner {
      border-radius: 4px;
      height: 335px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .placeholder {
        width: 100%;
        height: 335px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #bfbfbf;
        background-color: #e8e8e8;
      }

      font-style: normal;
    }
  }

  .v2-see-it-live {
    .inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      p {
        width: 65%;
        text-align: center;
        margin-bottom: 0;
      }

      .view-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        margin: 40px auto 0;

        .view-item {
          width: 100%;
          max-width: 50%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          img {
            max-width: 100%;
          }

          &.mobilo {
            max-width: 360px;
          }

          &.desktop {
            img {
              border: 1px solid #cacaca;
              border-top: none;
              border-radius: 10px;
            }
          }

          p:first-of-type {
            margin-bottom: 16px;
            font-weight: 600;
          }
        }
      }

      .cta-container {
        flex-direction: column;
        margin-top: 60px;

        .cta-offer {
          margin-top: 10px;
        }
      }
    }

    @include md {
      .inner {
        p {
          width: 100%;
          margin-bottom: 20px;
        }

        .mobilo-mobilo {
          margin-bottom: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 100%;

          .mobilo {
            max-height: 350px;
          }

          .circle {
            position: absolute;
            z-index: -1;
          }
        }

        .cta-container {
          margin-top: 0;
        }
      }
    }
  }

  .v2-updating-menu {
    .inner {
      padding: 100px 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      p {
        width: 50%;
        text-align: center;
      }
    }

    @include md {
      .inner {
        padding: 50px 10px;
        width: 100%;
        max-width: 400px;

        p {
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }

  .v2-faq {
    .inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .items-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0 100px;

        .item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          border-bottom: 1px solid #d9d9d9;
          padding: 25px 0;

          p {
            width: 100%;
            max-width: 60%;
          }

          .question {
            font-weight: 600;
            width: 100%;
            max-width: 40%;
            padding-right: 100px;
          }
        }
      }

      @include md {
        .items-list {
          padding: 0;

          .item {
            flex-direction: column;

            &:first-of-type {
              padding-top: 0;
            }

            p,
            .question {
              max-width: 100%;
              padding: 0;
              margin-bottom: 20px;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .v2-contact-form {
    overflow-x: hidden;

    .inner {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 120px 20px;

      .thank-you {
        width: 100%;
        text-align: center;
        font-size: 20px;
        line-height: 30px;

        .subtitle {
          color: $color-v2-orange;
          font-weight: 600;
        }
      }

      .contact-form {
        width: 350px;
        background: #fff;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        margin-left: 100px;
        padding: 30px;

        p,
        h3 {
          margin: 0;
          margin-bottom: 15px;
        }

        .input-item {
          margin-bottom: 15px;

          label {
            width: 100%;
            height: fit-content;
            border: none;
            background-color: transparent;
            padding: 0;
            color: #000;
            font-size: 14px;
          }

          textarea,
          input {
            width: 100%;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            outline: none;

            &::placeholder {
              color: #bfbfbf;
            }
          }

          textarea:focus,
          input:focus {
            box-shadow: 0 0 5px #ffd666 !important;
          }

          textarea {
            color: #9e9e9e;
            resize: none;
          }
        }
      }

      .wallpapers {
        position: absolute;
        right: 50px;
        z-index: -1;
        width: 100%;
        max-width: 60%;
      }

      @include md {
        padding: 120px 10px 450px;

        .contact-form {
          width: 100%;
          max-width: 100%;
          margin-left: 0;
        }

        .wallpapers {
          max-width: 100%;
          right: 0;
          top: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
