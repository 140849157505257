$font-regular: 300;
$font-bold: 500;

$color-blue-green: #019d97;
$color-button-green: #17a2b8;
$color-button-invalid: #d9534f;

$color-blue: #4dc6e4;
$color-blue-light: #7299b6;
$color-blue-dark: #466d8a;
$color-blue-darkest: #1e3a4f;
$color-blue-gray: #cfdce6;
$color-blue-gray-2: #b0c4d9;
$color-gray: #f5f7f7;
$color-gray-light: #efefef;
$color-gray-dark: #313335;
$color-white: #fff;
$color-green: #a6cc45;
// $color-v2-orange: #fa541c; OLD ORANGE
$color-v2-orange: #ff7a45; // NEW ORANGE!!!

$color-v2-orange-dark: #da3600;

$color-border-main: #ccc;

$screen-xs: 374px;
$screen-sm: 575px;
$screen-md: 767px;
$screen-lg: 991px;
$screen-xl: 1199px;
