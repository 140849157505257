.menu-manager {
  background-color: white;
  width: 100%;
  height: max-content;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 20px;

  &-header {
    width: 100%;
    height: 100px;
    padding: 30px 10px;
    display: flex;

    .inner {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      h2 {
        line-height: 100%;
        max-width: max-content;
        margin-bottom: 0;

        & > span {
          display: flex;
          align-items: center;
          height: 32px;
          width: 100%;

          span {
            display: inline-block;
            height: 30px;
            width: 250px;
          }
        }
      }

      .skeleton-button {
        width: 200px;
      }
    }

    @include lg {
      height: max-content;
      padding: 20px 10px;
      margin: 0 auto;

      .inner {
        max-width: 800px;
        gap: 10px;

        h2 {
          font-size: 1.5rem;

          & > span {
            display: flex;
            align-items: center;
            height: 24px;
            width: 100%;

            span {
              display: inline-block;
              height: 22px;
              width: 160px;
            }
          }
        }

        .skeleton-button {
          width: 150px;
        }
      }
    }
  }

  .menu-list-empty {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: auto 0;
    box-sizing: border-box;
    overflow-x: hidden;
    align-items: center;

    h3 {
      line-height: 100%;
      max-width: max-content;
      color: #7b7b7b;
      margin-bottom: 50px;
    }

    .placeholder {
      width: 600px;
    }

    @include lg {
      h3 {
        font-size: 1.5rem;
      }

      .placeholder {
        width: 400px;
      }
    }

    @include sm {
      h3 {
        font-size: 1.5rem;
      }

      .placeholder {
        width: 300px;
      }
    }
  }

  .menu-list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;

    .menu-item {
      width: 100%;
      height: fit-content;
      background: #fff;
      border: 1px solid #dadada;
      box-sizing: border-box;
      box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-bottom: 30px;
      padding: 30px;
      display: flex;
      justify-content: space-between;
      transition: all 0.5s ease-out;

      .content-block {
        display: flex;
        flex-direction: column;
        width: 100%;

        .name-and-edit {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          h3 {
            font-size: 26px;
            line-height: 100%;
            color: #55595c;
            margin-bottom: 15px;
            width: 100%;
            word-break: break-all;

            & > span {
              display: flex;
              align-items: center;
              height: 26px;
              width: 100%;

              span {
                display: inline-block;
                height: 24px;
                width: 160px;
              }
            }
          }

          .button-block {
            width: 100%;
            max-width: 350px;
            gap: 20px;
            min-width: fit-content;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .language-url-qr {
          h4 {
            font-size: 16px;
            line-height: 21px;
            color: #55595c;
            margin-bottom: 15px;

            &.disabled {
              color: #a5a5a5;
            }

            span {
              display: inline-block;
              height: 100%;
              width: 200px;
            }
          }

          .link-container {
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            & > a {
              text-decoration-line: underline;
              color: $color-v2-orange;
              display: inline-block;
            }

            & > span {
              color: #a5a5a5;
              display: inline-block;
            }

            .skeleton-container {
              display: inline-block;

              span {
                display: inline-block;
                height: 100%;
                width: 220px;
              }
            }
          }

          .copy-url-show-qr {
            margin: 30px 0;
            display: flex;

            &-inner {
              gap: 20px;
              display: inline-flex;
              flex-wrap: wrap;

              .skeleton-button {
                width: 150px;
              }
            }
          }
        }

        .translations {
          background: #fff;
          border: 1px solid #dadada;
          box-sizing: border-box;
          box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          padding: 15px 30px;
          width: 100%;
          transition: all 0.5s ease-out;
          height: 55px;
          overflow: hidden;
          cursor: pointer;

          &-title {
            display: inline-block;
            margin-left: 15px;

            h4 {
              font-size: 16px;
              line-height: 21px;
              color: #55595c;
              margin-bottom: 0;
            }
          }

          .language-url-qr {
            width: 100%;
            padding-top: 15px;
            margin: 0 auto;
            cursor: auto;

            &:not(:last-child) {
              &::after {
                position: relative;
                top: 0;
                padding-top: 15px;
                display: block;
                content: '';
                width: 100%;
                height: 0;
                border-bottom: 1px solid #ccc;
              }
            }

            .link-container {
              font-size: 16px;
              line-height: 21px;
              margin: 0 0 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              & > a {
                text-decoration-line: underline;
                color: $color-v2-orange;
                display: inline-block;
              }

              & > span {
                color: #a5a5a5;
                display: inline-block;
              }
            }

            .copy-url-show-qr {
              margin: 30px 0 15px;
            }
          }

          &::before {
            position: relative;
            display: inline-block;
            content: '';
            width: 12px;
            height: 12px;
            border-right: 2px solid $color-v2-orange;
            border-bottom: 2px solid $color-v2-orange;
            transform: rotate(-45deg);
            transition: all 0.5s ease-out;
          }

          &.open {
            height: 100%;

            &::before {
              transform: rotate(45deg);
              transition: all 0.5s ease-out;
            }
          }
        }
      }

      .logo-block {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        width: 300px;
        height: fit-content;
        padding: 0;
        margin-left: 30px;

        .updated-at {
          margin-bottom: 20px;

          h4 {
            color: #a5a5a5;
            font-size: 16px;
            font-weight: normal;
            line-height: 20px;
            margin-bottom: 0;
            text-align: right;

            & > span {
              display: flex;
              align-items: center;
              height: 20px;
              width: 100%;
              justify-content: flex-end;

              span {
                display: inline-block;
                height: 16px;
                width: 240px;
              }
            }
          }
        }

        .logo {
          height: 300px;
          width: 300px;

          img {
            object-fit: contain;
            object-position: right top;
            height: 100%;
            width: 100%;
          }

          span {
            display: block;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  @include lg {
    max-width: 100%;
    padding: 0;

    .menu-list-empty {
      max-width: 100%;
      overflow-x: hidden;

      h1 {
        font-size: 24px !important;
        width: 100%;
        text-align: center;
      }
    }

    .menu-list {
      .menu-item {
        padding: 10px;
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 20px;

        .content-inner {
          width: 100%;
          height: min-content;

          .delete-clone-btns {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;
          }

          .updated-at {
            margin-bottom: 10px;

            h4 {
              color: #a5a5a5;
              font-size: 12px;
              font-weight: normal;
              line-height: 16px;
              margin-bottom: 0;
              text-align: right;

              & > span {
                display: flex;
                align-items: center;
                height: 16px;
                width: 100%;
                justify-content: flex-end;

                span {
                  display: inline-block;
                  height: 12px;
                  width: 200px;
                }
              }
            }
          }

          .default-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .content {
              width: 100%;
              max-width: calc(100% - 100px);
              display: flex;
              flex-direction: column;
              height: 100px;

              h3 {
                font-size: 20px;
                line-height: 125%;
                margin: 10px 0;
                word-break: break-all;

                & > span {
                  display: flex;
                  align-items: center;
                  height: 25px;
                  width: 100%;

                  span {
                    display: inline-block;
                    height: 20px;
                    width: 140px;
                  }
                }
              }
            }

            .logo {
              height: 100px;
              width: 100px;
              margin-left: 0;

              img {
                height: 100%;
                width: 100%;
              }

              span {
                display: block;
                height: 100%;
                width: 100%;
              }
            }
          }

          .language-url-qr {
            h4 {
              font-size: 14px;
              line-height: 14px;
              margin-bottom: 10px;

              &.disabled {
                color: #a5a5a5;
              }

              span {
                display: inline-block;
                height: 100%;
                width: 180px;
              }
            }

            .link-container {
              font-size: 14px;
              line-height: 14px;
              margin: 0 0 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              & > a {
                text-decoration-line: underline;
                color: $color-v2-orange;
                display: inline-block;
              }

              & > span {
                color: #a5a5a5;
                display: inline-block;
              }

              .skeleton-container {
                display: inline-block;

                span {
                  display: inline-block;
                  height: 100%;
                  width: 200px;
                }
              }
            }

            .copy-url-show-qr {
              width: 100%;
              display: flex;
              margin: 15px 0 10px;

              &-inner {
                gap: 10px;
                display: inline-flex;
                flex-wrap: wrap;

                .skeleton-button {
                  width: 120px;
                }
              }
            }
          }

          .translations {
            background: #fff;
            border: 1px solid #dadada;
            box-sizing: border-box;
            box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 10px;
            height: 45px;
            width: 100%;
            transition: all 0.5s ease-out;
            overflow: hidden;
            cursor: pointer;

            &-title {
              display: inline-block;
              margin-left: 15px;

              h4 {
                font-size: 14px;
                line-height: 14px;
                color: #55595c;
                margin-bottom: 0;
              }
            }

            .language-url-qr {
              width: 100%;
              padding: 15px 0 0;
              margin: 0 auto;
              cursor: auto;

              &:not(:last-child) {
                &::after {
                  position: relative;
                  top: 0;
                  padding-top: 10px;
                  display: block;
                  content: '';
                  width: 100%;
                  height: 0;
                  border-bottom: 1px solid #ccc;
                }
              }

              h4 {
                font-size: 14px;
                line-height: 14px;

                &.disabled {
                  color: #a5a5a5;
                }
              }

              .link-container {
                font-size: 14px;
                line-height: 14px;
                margin: 0 0 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                & > a {
                  text-decoration-line: underline;
                  color: $color-v2-orange;
                  display: inline-block;
                }

                & > span {
                  color: #a5a5a5;
                  display: inline-block;
                }
              }

              .copy-url-show-qr {
                margin: 15px 0 0;
              }
            }

            &::before {
              position: relative;
              display: inline-block;
              content: '';
              width: 12px;
              height: 12px;
              border-right: 2px solid $color-v2-orange;
              border-bottom: 2px solid $color-v2-orange;
              transform: rotate(-45deg);
              transition: all 0.5s ease-out;
            }

            &.open {
              height: 100%;
              transition: all 0.5s ease-out;

              &::before {
                transform: rotate(45deg);
                transition: all 0.5s ease-out;
              }
            }
          }
        }
      }
    }
  }
}
