.control-box {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0 0;

  @include sm {
    flex-direction: column;
  }

  .expand-rollup-block {
    width: 100%;
    max-width: max-content;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @include sm {
      max-width: 100%;
    }
  }
}

.menu-list {
  margin-left: 0;
  margin-right: 0;

  &-inner {
    padding-left: 0;
    padding-right: 0;

    .categories-container {
      .category-container,
      .subcategory-container,
      .item-container {
        border-radius: 8px;
        margin-bottom: 10px;
        padding-right: 0;
        padding-left: 0;
        transition: all 0.4s ease-in-out;

        &:last-child {
          margin-bottom: 0;
        }

        .input-group-sm > .form-control:not(textarea),
        .input-group-sm > .input-group-prepend > .input-group-text {
          height: 38px;
          font-size: inherit;
          font-style: normal;
          font-weight: normal;
          line-height: 100%;
          padding: 4px 8px;

          @include lg {
            height: 34px;
            font-size: 14px;
          }

          @include md {
            height: 30px;
          }
        }

        .hint-row {
          width: calc(100% - 40px);

          @include lg {
            display: flex;
            flex-flow: row nowrap;
          }

          .hint-content {
            font-size: 16px;
            color: #b7b7b7;
            background-color: transparent;
            border: 1px solid transparent;
            margin: 0;
            padding: 0;
            flex-wrap: nowrap;

            .input-group-prepend {
              .input-group-text {
                color: transparent;
                background-color: transparent;
                border: 1px solid transparent !important;
                margin: 0 !important;
                padding: 0 !important;
                padding-left: 15px !important;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
              }
            }

            .hint-container {
              background: rgba(255, 255, 255, 0.5);
              color: #b0b0b0;
              margin-bottom: 5px;
              padding: 0 10px;
              border-radius: 8px 8px 8px 0;
            }
          }

          &-description {
            width: 100%;
            margin-top: 0;

            .hint-content {
              .hint-container {
                margin-top: 0;
              }
            }
          }
        }

        .unit-header-subvariant {
          width: 100%;
          padding: 10px 0 0 0;
          height: fit-content;
          margin: 0;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          @include lg {
            padding: 10px 0;
          }

          .expand-btn {
            cursor: s-resize;
            position: relative;
            width: 12px;
            height: 12px;
            left: 10px;
            margin-right: 12px;
            border-right: 2px solid $color-v2-orange;
            border-bottom: 2px solid $color-v2-orange;
            transform: rotate(-45deg);
            transform-origin: center center;
            transition: all 0.3s ease-out;
            z-index: 10;

            @include lg {
              align-self: flex-end;
              bottom: 11px;
            }
          }

          &.open {
            .expand-btn {
              cursor: n-resize;
              transform: rotate(45deg);
              transform-origin: center center;
              transition: all 0.3s ease-out;
            }
          }

          .btn-delete {
            cursor: pointer;
            width: 24px;
            height: 24px;
            background: none;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;
          }

          .btn-hide {
            cursor: pointer;
            width: 24px;
            height: 24px;
            background: none;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;

            input[type='checkbox'] {
              display: none;
            }
          }

          .btn-frozen {
            cursor: pointer;
            width: 24px;
            height: 24px;
            background: none;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;

            input[type='checkbox'] {
              display: none;
            }
          }

          .drag-up {
            cursor: grab;
            width: 24px;
            height: 24px;
            background: none;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;

            &.disabled {
              cursor: default;
            }
          }

          &.translated {
            .expand-btn {
              align-self: flex-end;
              bottom: 11px;
            }

            .name-and-delete {
              max-width: 100%;

              .actions-block {
                .btn-hide {
                  margin-left: 6px;
                  margin-right: 10px;
                }
              }
            }

            .default-language-buttons {
              justify-content: center;
            }
          }

          .name-and-delete {
            width: 100%;
            max-width: calc(100% - 90px);
            height: 100%;
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 10px;

            @include lg {
              max-width: 100%;
            }

            .default-language-buttons {
              width: fit-content;
              min-width: 90px;
              margin-bottom: 7px;
              align-self: flex-end;
              right: 5px;
            }

            .actions-block {
              width: 100%;
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-start;
              align-items: center;

              @include lg {
                .drag-down {
                  margin-left: 5px;
                  margin-right: 5px;
                }
              }

              .input-group {
                flex-wrap: nowrap;
                width: 100%;
              }

              .btn-delete {
                margin-right: 15px;
                margin-left: 15px;
              }

              .btn-hide {
                margin-left: 10px;
                margin-right: 10px;
              }

              .btn-frozen {
                margin-left: 10px;
              }
            }
          }

          .default-language-buttons {
            max-width: 70px;
            height: 100%;
            padding-left: 0;
            padding-right: 0;
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;

            .drag {
              cursor: grab;
            }
          }
        }

        .unit-header {
          width: 100%;
          padding: 20px 0;
          height: fit-content;
          margin: 0;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          @include lg {
            padding: 10px 0;
          }

          .expand-btn {
            cursor: s-resize;
            position: relative;
            width: 12px;
            height: 12px;
            left: 10px;
            margin-right: 12px;
            border-right: 2px solid $color-v2-orange;
            border-bottom: 2px solid $color-v2-orange;
            transform: rotate(-45deg);
            transform-origin: center center;
            transition: all 0.3s ease-out;
            z-index: 10;

            @include lg {
              align-self: flex-end;
              bottom: 11px;
            }
          }

          &.open {
            .expand-btn {
              cursor: n-resize;
              transform: rotate(45deg);
              transform-origin: center center;
              transition: all 0.3s ease-out;
            }
          }

          .btn-delete {
            cursor: pointer;
            width: 24px;
            height: 24px;
            background: none;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;
          }

          .btn-hide {
            cursor: pointer;
            width: 24px;
            height: 24px;
            background: none;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;

            input[type='checkbox'] {
              display: none;
            }
          }

          .btn-columns {
            cursor: pointer;
            width: 24px;
            height: 24px;
            background: none;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;

            input[type='checkbox'] {
              display: none;
            }
          }

          .btn-frozen {
            cursor: pointer;
            width: 24px;
            height: 24px;
            background: none;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;

            input[type='checkbox'] {
              display: none;
            }
          }

          .drag-up {
            cursor: grab;
            width: 24px;
            height: 24px;
            background: none;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;

            &.disabled {
              cursor: default;
            }
          }

          &.translated {
            .expand-btn {
              align-self: flex-end;
              bottom: 11px;
            }

            .name-and-delete {
              max-width: 100%;

              .actions-block {
                .btn-hide {
                  margin-left: 6px;
                  margin-right: 10px;
                }
              }
            }

            .default-language-buttons {
              justify-content: center;
            }
          }

          .name-and-delete {
            width: 100%;
            max-width: calc(100% - 90px);
            height: 100%;
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 8px;

            @include lg {
              max-width: 100%;
            }

            .default-language-buttons {
              width: fit-content;
              min-width: 90px;
              margin-bottom: 7px;
              align-self: flex-end;
              right: 5px;
            }

            .actions-block {
              width: 100%;
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-start;
              align-items: center;

              @include lg {
                .drag-down {
                  margin-left: 5px;
                  margin-right: 5px;
                }
              }

              .input-group {
                flex-wrap: nowrap;
                width: 100%;
              }

              .btn-delete {
                margin-right: 15px;
                margin-left: 15px;
              }

              .btn-hide {
                margin-left: 10px;
                margin-right: 10px;
              }

              .btn-columns {
                margin-left: 10px;
              }

              .btn-frozen {
                margin-left: 10px;
              }
            }
          }

          .default-language-buttons {
            max-width: 70px;
            height: 100%;
            padding-left: 0;
            padding-right: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .drag {
              cursor: grab;
            }
          }
        }

        .variant-name-input {
          margin-top: 10px;
        }

        .unit-content {
          padding-right: 10px;
          padding-left: 10px;
          padding-bottom: 10px;

          .variant-container {
            border: none;
            background-color: inherit;
            margin-bottom: 10px;
            margin-top: 10px;

            .variant-card {
              background-color: #fff2e8;
              padding: 10px;
              border-radius: 4px;
              width: 100%;
              margin: 0;
              display: flex;
              flex-direction: column;
              gap: 10px;
              position: relative;
            }

            /* Первая строка - крестик справа */
            .variant-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            /* Вторая строка - инпут и контент */
            .variant-body {
              display: flex;
              flex-direction: column;
              gap: 10px;
            }

            .close-icon {
              cursor: pointer;
              z-index: 10;
              background-color: #fff2e8;
              padding: 2px;
              border-radius: 50%;
            }

            .measurements {
              display: flex;
              gap: 10px; /* Расстояние между элементами */
              align-items: center;
            }
          }

          .description {
            border: none;
            border-radius: 4px;
            width: 100%;
            margin: 0;

            .card-header {
              align-items: center;
              background: #eceeef;
              border: 1px solid $color-border-main;
              border-radius: 4px 4px 0 0;
              box-sizing: border-box;
              color: #55595c;
              display: flex;
              font-style: normal;
              font-weight: normal;
              height: 38px;
              line-height: 100%;
              padding: 4px 8px;

              @include lg {
                height: 34px;
                font-size: 14px;
              }

              @include md {
                height: 30px;
              }
            }

            textarea {
              outline: none;
              resize: none;
              overflow: auto;
              background: #fff;
              border: 1px solid $color-border-main;
              border-top: none;
              border-radius: 0 0 4px 4px;
              color: #55595c;
            }
          }

          .measurements {
            margin-right: 0;
            margin-left: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            gap: 10px;

            @include sm {
              flex-flow: column nowrap;
              justify-content: flex-start;
              align-items: flex-start;
            }

            .btn-toggle-list-mode {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background: none;
              padding: 0;
              border: none;
            }

            .measure-block {
              width: 50%;
              padding: 0;

              @include sm {
                width: 100%;
              }

              .input-group {
                flex-wrap: nowrap;
                width: 100%;

                .dropdown-toggle {
                  background: padding-box #fff;
                  height: 100%;
                  line-height: 100%;
                  border: 1px solid $color-border-main;
                  border-left: 1px solid transparent;
                  border-radius: 0 4px 4px 0;
                  color: #55595c;

                  &:focus {
                    box-shadow: 0 0 5px $color-button-green;
                    -moz-box-shadow: 0 0 5px $color-button-green;
                    -webkit-box-shadow: 0 0 5px $color-button-green;
                    border-color: transparent;
                  }
                }
              }
            }

            .price-block {
              width: 50%;
              padding: 0;
              display: flex;
              flex-flow: row nowrap;

              .input-group {
                flex-wrap: nowrap;
                justify-content: flex-end;
                width: 100%;
              }

              @include sm {
                width: 100%;

                .input-group {
                  justify-content: flex-start;
                }
              }
            }

            .sku-block {
              width: 50%;
              padding: 0;
              display: flex;
              flex-flow: row nowrap;

              .input-group {
                flex-wrap: nowrap;
                justify-content: flex-end;
                width: 100%;
              }

              @include sm {
                width: 100%;

                .input-group {
                  justify-content: flex-start;
                }
              }
            }
          }
        }
      }

      .category-container {
        background-color: #fff2e8;
        margin-bottom: 20px;

        &.grayed {
          background-color: #f5f5f5;

          .subcategory-container {
            background-color: #e2e2e2;

            .item-container {
              background-color: #c7c5c5;
            }

            .item-container-variant {
              background-color: #e2e2e2;
            }
          }
        }
      }

      .subcategory-container {
        background-color: #ffe5d2;

        &.grayed {
          background-color: #e2e2e2;

          .item-container {
            background-color: #c7c5c5;
          }

          .item-container-variant {
            background-color: #e2e2e2;
          }
        }
      }

      .item-container {
        background-color: #ffc59a;

        &.grayed {
          background-color: #c7c5c5;

          .item-container-variant {
            background-color: #e2e2e2;
          }
        }
      }

      .item-container-variant {
        padding: 0;
        background-color: #fff2e8;
        margin-top: 10px;
        border-radius: 4px;

        &.grayed {
          background-color: #e2e2e2;
        }
      }

      .skeleton-container {
        margin-bottom: 20px;
        height: 74px;
        width: 100%;

        @include lg {
          height: 85px;
        }

        span {
          border-radius: 8px;
          display: block;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
