@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Noto+Sans+Thai:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~bootstrap/scss/bootstrap';
@import '~alertifyjs/build/css/themes/bootstrap.min.css';
@import '~react-toastify/dist/ReactToastify.min.css';
@import '~alertifyjs/build/css/alertify.min.css';
@import 'abstract/mixins';
@import 'abstract/variables';
@import 'base/base';
@import 'base/reset';
@import 'base/typography';
@import 'layout/header-v2';
@import 'layout/footer-v2';
@import 'components/menu-manager';
@import 'components/constructor';
@import 'components/contentList';
@import 'components/viewer';
@import 'pages/home-page-v2';
@import 'pages/summary-page';
@import 'pages/menu-page';
@import 'pages/contact-page';
@import 'pages/secondary-page';
