.v2-footer {
  font-family: 'Noto sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c8c8c;

  .inner {
    width: 100%;
    height: 100%;
    padding: 30px 20px;

    .top-row {
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;

      .menu-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        .logo-link {
          .logo-image {
            width: 100%;
            min-width: 100%;
            height: auto;
          }
        }

        .menu {
          width: 100%;
          max-width: fit-content;
          display: flex;
          gap: 10px;

          .lang-selector {
            outline: none;

            &::-ms-expand {
              display: none;
            }
          }
        }
      }

      .footer-menu {
        display: flex;
        gap: 30px;
        height: min-content;

        .footer-link {
          color: #8c8c8c;

          &:hover {
            cursor: pointer;
            text-decoration: none;
          }

          span {
            font-weight: 600;
          }
        }
      }
    }

    .bottom-row {
      text-align: left;
      padding-top: 30px;

      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  @include md {
    .inner {
      max-width: 100%;
      padding: 20px 10px;
      gap: 20px;

      .top-row {
        align-items: center;
        flex-direction: column;
        padding-bottom: 20px;

        .menu-container {
          width: 100%;
          padding-bottom: 20px;
        }

        .footer-menu {
          width: 100%;
          padding-top: 20px;
          border-top: 1px solid #d9d9d9;
          justify-content: center;
          align-items: center;

          .footer-link {
            span {
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }

      .bottom-row {
        text-align: center;
        padding-top: 20px;

        p {
          font-weight: 800;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  @include sm {
    height: fit-content;

    .inner {
      .top-row {
        .menu-container {
          flex-direction: column;
        }

        .footer-menu {
          flex-direction: column;
        }
      }
    }
  }
}
