h2,
h3,
h4,
h5 {
  line-height: 50%;
}

h1 {
  font-size: 40px;
  margin: 0 auto;
}

h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
}

p {
  font-size: 20px;
}
