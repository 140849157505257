.secondary-page {
  padding: 0 15px 100px;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  max-width: 1150px;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  .text {
    width: 100%;
    max-width: 630px;
    min-height: calc(100vh - 200px);
    // padding-right: 30px;
    margin-bottom: 90px;
    height: fit-content;
    font-family: 'Arimo', sans-serif;
    font-style: normal;
    font-weight: normal;

    h1 {
      font-size: 40px;
      line-height: 100%;
      margin-top: 40px;
      margin-bottom: 40px;
      font-weight: 400;
    }

    p {
      font-size: 16px;
      line-height: 125%;
      text-align: left;

      &.green {
        color: $color-v2-orange;
      }
    }

    h5 {
      font-size: 16px;
      line-height: 125%;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 0;
    }

    ul {
      margin-left: 25px;

      li {
        list-style-position: outside;
        list-style-type: disc;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .last-p {
      margin-bottom: 100px;
    }
  }

  .pattern-container {
    position: absolute;
    left: 630px;
    top: 0;
    width: 100%;
    max-width: 520px;
    padding-left: 70px;
    height: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 100%;
      max-width: 275px;
      height: inherit;
      align-self: flex-start;
      margin-top: 100px;

      &:nth-of-type(2n + 1) {
        align-self: flex-end;
      }
    }

    &.sm {
      img {
        margin-top: 50px;
      }
    }
  }

  @include md {
    padding: 0 10px;

    .text {
      max-width: 100%;
      min-width: 100%;
      margin-right: 0;
      margin-bottom: 0;
      padding-right: 0;
    }

    .pattern-container {
      display: none;
    }
  }

  @include sm {
    padding-top: 80px;
    padding-bottom: 180px;
  }
}

.company-page {
  .text {
    p {
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }

    ul {
      margin: 30px;
      margin-right: 0;
    }
  }
}
