html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  flex-grow: 1;
}

html,
body,
.app,
header,
main,
ul,
article,
p,
a {
  @include spacing;

  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.form-control,
.input-group-text {
  border-color: $color-border-main;
}

input {
  transition: box-shadow 300ms ease-out;
}

.form-control:focus,
textarea:focus,
input:focus {
  -moz-box-shadow: 0 0 5px $color-button-green;
  -webkit-box-shadow: 0 0 5px $color-button-green;
  box-shadow: 0 0 5px $color-button-green;
  border-color: transparent;

  &.invalid {
    -moz-box-shadow: 0 0 5px $color-button-invalid;
    -webkit-box-shadow: 0 0 5px $color-button-invalid;
    box-shadow: 0 0 5px $color-button-invalid;
    border-color: transparent;
  }
}
