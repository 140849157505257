@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Arimo:400,700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Arimo', sans-serif;
}

div.main {
  position: relative;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: max-content;
  min-height: 100vh;
  max-width: 1280px;
  min-width: 350px;
  width: 100%;
  margin: 0 auto;
}

li {
  list-style-type: none;
}

.top-line {
  border-top: 1px solid $color-border-main;
  margin-top: 30px;
  padding-top: 30px;
}

.drag {
  cursor: row-resize;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-bottom: 0;
}

input,
textarea {
  background: padding-box #fff;
  border: 1px solid $color-border-main;
  padding: 4px 8px;
  color: #9e9e9e;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;

  @include lg {
    font-size: 14px;
  }
}

input {
  height: 38px;
  line-height: 100%;

  @include lg {
    height: 34px;
  }

  @include md {
    height: 30px;
  }
}

textarea {
  line-height: 150%;
}

label {
  height: 38px;
  margin: 0;
  color: #55595c;
  background: #eceeef;
  padding: 4px 8px;
  border: 1px solid $color-border-main;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;

  @include lg {
    height: 34px;
    font-size: 14px;
  }

  @include md {
    height: 30px;
  }
}

input[type='file'] {
  display: none;
}

.select-container {
  width: 100%;
  height: 100%;
  text-align: left;
}

.select-container__control {
  background: padding-box #fff;
  border: 1px solid $color-border-main;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  flex-wrap: nowrap !important;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  height: 38px;
  min-height: 38px !important;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  width: 100%;

  @include lg {
    height: 34px;
    min-height: 34px !important;
    font-size: 14px;
  }

  @include md {
    height: 30px;
    min-height: 30px !important;
  }

  &--is-focused {
    box-shadow: 0 0 5px $color-button-green;
    -moz-box-shadow: 0 0 5px $color-button-green;
    -webkit-box-shadow: 0 0 5px $color-button-green;
    border-color: transparent;

    .select-container__indicators {
      .select-container__indicator {
        color: hsl(0, 0%, 40%);

        &:hover {
          color: hsl(0, 0%, 20%);
        }
      }
    }
  }
}

.select-container__value-container {
  margin: 0 8px;
}

.select-container__placeholder {
  align-items: center;
  color: hsl(0, 0%, 80%);
  display: flex;
}

.select-container__input-container {
  .select-container__input {
    color: #495057;
    box-shadow: none !important;
    line-height: 100% !important;
  }
}

.select-container__indicators {
  height: 100%;
  width: 36px;

  @include lg {
    width: 32px;
  }

  @include md {
    width: 28px;
  }

  .select-container__indicator-separator {
    background-color: hsl(0, 0%, 80%);
    margin: 2px 0;
  }

  .select-container__indicator {
    align-items: center;
    color: hsl(0, 0%, 80%);
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;

    &:hover {
      color: hsl(0, 0%, 60%);
    }
  }
}

.select-container__menu {
  background-color: hsl(0, 0%, 100%);
  border-radius: 0; //4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin: 4px 0;
}

.select-container__menu-list {
  scroll-snap-type: y mandatory;
  max-height: calc(4 * 38px) !important;

  @include lg {
    max-height: calc(4 * 34px) !important;
  }

  @include md {
    max-height: calc(4 * 30px) !important;
  }
}

.select-container__menu-notice {
  align-items: center;
  display: flex;
  font-style: normal;
  font-weight: normal;
  height: 38px;
  justify-content: center;
  line-height: 100%;
  padding: 0 7px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include lg {
    font-size: 14px !important;
    height: 34px;
  }

  @include md {
    height: 30px;
  }
}

.select-container__option {
  scroll-snap-align: start;
  scroll-margin: 0;
  align-items: center;
  background-color: transparent;
  color: #495057;
  display: flex !important;
  font-style: normal;
  font-weight: normal;
  height: 38px;
  line-height: 100%;
  padding: 0 7px;

  @include lg {
    font-size: 14px !important;
    height: 34px;
  }

  @include md {
    height: 30px;
  }

  &:active {
    background-color: hsl(0, 0%, 90%);
  }

  &:hover {
    background-color: hsl(0, 0%, 90%);
  }
}

.toggleHide {
  display: flex;

  input[type='checkbox'] {
    display: none;
  }

  .input-label {
    background: none;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;

    @include md {
      width: 20px;
      height: 20px;
    }
  }
}

.delete-button {
  cursor: pointer;
  margin-left: 10px;
}

.modal-backdrop {
  opacity: 0;
}

.remove-modal-dialog {
  .modal-dialog {
    top: calc(50% - 140px);
    width: 100%;
    max-width: 250px;

    .modal-content {
      width: 100%;

      .modal-header {
        border-bottom: 1px solid transparent;
      }

      .modal-footer {
        border-top: 1px solid transparent;
        padding-top: 0;
      }

      background: #fff;
      border: 1px solid #c12e2a !important;
      box-sizing: border-box;
      border-radius: 8px 8px 8px 0;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #c12e2a;
    }
  }
}

.alias-modal-dialog {
  .modal-dialog {
    width: 100%;
    max-width: 500px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    @include md {
      max-width: 350px;
    }

    .modal-content {
      width: 100%;
      max-height: 440px;
      height: 100%;
      background: #fff;
      color: #55595c;
      padding: 30px;
      border: 3px solid $color-v2-orange;
      border-radius: 8px;
      text-align: center;

      @include lg {
        padding: 20px;
      }

      h5 {
        width: 100%;
        margin: 10px 0;
        font-size: 16px;
        line-height: 150%;
        font-weight: normal;
        text-align: center;
      }

      .modal-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        border-bottom: none;

        h4 {
          width: 100%;
          font-family: 'Arimo', sans-serif;
          font-size: 22px;
          font-weight: bold;
          line-height: 100%;
          margin: 0;
        }

        .progress-block {
          margin: 15px 0;

          p {
            font-size: 16px;
            margin: 0;
          }

          .progress-bar {
            background-color: #bdbdbd;
            width: 200px;
            height: 3px;
            margin: 0 auto;

            &-inner {
              background-color: $color-v2-orange;
              height: 100%;

              &.step {
                &-1 {
                  width: 33%;
                }

                &-2 {
                  width: 66%;
                }

                &-3 {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .modal-body {
        padding: 0 3px;

        p {
          font-size: 16px;
          line-height: 150%;
          margin: 0;
          text-align: left;

          &.top {
            margin-bottom: 10px;
          }

          span {
            color: $color-v2-orange;
            font-weight: bold;
          }
        }

        .form-control {
          padding: 4px 8px;
          font-style: normal;
          font-weight: normal;
          line-height: 100%;

          @include lg {
            height: 34px;
            font-size: 14px;
          }

          @include md {
            height: 30px;
          }

          &:focus {
            box-shadow: 0 0 5px $color-button-green;
            -moz-box-shadow: 0 0 5px $color-button-green;
            -webkit-box-shadow: 0 0 5px $color-button-green;
            border-color: transparent;
          }

          &.invalid {
            &:focus {
              box-shadow: 0 0 5px $color-button-invalid;
              -moz-box-shadow: 0 0 5px $color-button-invalid;
              -webkit-box-shadow: 0 0 5px $color-button-invalid;
              border-color: transparent;
            }
          }
        }

        .alias-input {
          text-transform: lowercase;
          margin-top: 5px;
        }

        .alias-info {
          height: fit-content;
          min-height: 42px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .alias-hint {
            width: 100%;
            font-size: 14px;
            line-height: 100%;
            text-align: start;
            margin: 5px 0;
            color: $color-button-invalid;
          }
        }

        .alias-preview {
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 60px;
          padding-left: 5px;
          padding-right: 5px;

          .alias-preview-text {
            width: fit-content;
            margin-bottom: 0;
            line-height: 100%;
            white-space: nowrap;
          }
        }

        .default-lang-select {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 5px;

          .select-container {
            max-width: 100%;

            .select-container__control {
              border-radius: 4px;
            }
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: center;
        border: none;
        padding: 0;
        padding-top: 10px;
      }
    }
  }

  &-alias-only {
    .modal-dialog {
      .modal-content {
        .modal-header {
          h4 {
            margin-bottom: 57px;
          }
        }
      }
    }
  }
}

.global-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  h1 {
    color: #00000080;
  }
}

.no-margin {
  margin-bottom: 0;
}

.custom-popover {
  .arrow {
    visibility: hidden;
  }

  border-color: transparent !important;
  background-color: transparent !important;

  &-inner {
    background-color: #fff !important;
    border: 2px solid $color-v2-orange;
    border-radius: 0 10px 10px 10px;
    padding: 0 10px;
    font-size: 16px;
    color: $color-v2-orange;
    margin: 2px 0 0 0;

    p {
      font-size: 16px;
      line-height: 125%;
      margin: 0;
      margin-top: 10px;

      &:last-of-type {
        margin-bottom: 10px;
      }
    }
  }
}

.Toastify__toast-container {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 9999;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar,
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1;
}

.custom-notification {
  cursor: auto;
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: #fff;
  border-radius: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .Toastify__toast-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }

  &.success {
    border: 2px solid #54c7ec;
    color: #54c7ec;

    .Toastify__progress-bar--animated {
      background-color: #54c7ec;
    }
  }

  &.warning {
    border: 2px solid #e7bd13;
    color: #e7bd13;

    .Toastify__progress-bar--animated {
      background-color: #e7bd13;
    }
  }

  &.error {
    border: 2px solid #e74c3c;
    color: #e74c3c;

    .Toastify__progress-bar--animated {
      background-color: #e74c3c;
    }
  }

  &.info {
    border: 2px solid #07bc0c;
    color: #07bc0c;

    .Toastify__progress-bar--animated {
      background-color: #07bc0c;
    }
  }

  h3 {
    line-height: 100%;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 0;
  }

  a {
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 50%;
    color: darkred;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.main-view {
  min-height: calc(100vh - 100px);
  width: 100%;
  padding-bottom: 145px;
  display: flex;
  flex-direction: column;
  background-color: white;

  .container {
    display: flex;
    flex-direction: column;
  }

  @include lg {
    padding-bottom: 100px;
    padding-left: 0;
    padding-right: 0;
  }
}

.cookies-warning-container {
  background: #353535;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 999;
  padding: 0 20px;
  bottom: -80px;
  left: 0;
  right: 0;
  transition: 0.5s all ease-in-out;

  &.showtime {
    bottom: 0;
    transition: 0.5s all ease-in-out;
  }

  .text-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 75%;
    height: 100%;

    p {
      margin: 0;
      font-size: 18px;
      line-height: 18px;

      span {
        color: $color-v2-orange;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .button-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 100%;

    .ok-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 50px;
      background: #fff;
      color: #353535;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background: #d5d5d5;
      }

      &:active {
        background: #939393;
      }

      p {
        margin: 0;
      }
    }
  }

  @include sm {
    padding: 5px 10px;
    bottom: -120px;

    &.en {
      height: 80px;
    }

    &.ru,
    &.uk {
      height: 100px;
    }
  }
}

.v2-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;
  height: 42px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 700;
  color: #fff;
  border: 1px solid $color-v2-orange;
  outline: none;
  border-radius: 3px;
  background: $color-v2-orange;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background: $color-v2-orange-dark;
  }

  &-light {
    border: 1px solid #d9d9d9;
    background-color: #fff;
    color: #4d4d4d;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &-outline {
    background-color: #fff;
    color: $color-v2-orange;

    &:hover {
      background-color: #fce4d8;
    }
  }

  &.sm {
    height: 38px;
    font-weight: 400;
    padding: 4px 8px;

    @include lg {
      font-size: 14px;
    }

    @include md {
      height: 30px;
    }
  }

  &.disabled {
    background-color: #fff;
    cursor: default;
    color: #a5a5a5;

    &:hover {
      background-color: #fff;
    }
  }

  @include lg {
    height: 38px;
    font-size: 14px;
  }

  @include md {
    height: 34px;
    padding: 4px 8px;
  }
}

.skeleton-button {
  display: flex;
  width: 120px;
  height: 42px;
  background-color: transparent;

  &.sm {
    height: 38px;

    @include md {
      height: 30px;
    }
  }

  @include lg {
    height: 38px;
  }

  @include md {
    height: 34px;
  }

  span {
    display: block;
    border-radius: 3px;
    height: 100%;
    width: 100%;
  }
}
