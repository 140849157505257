@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@700;800&family=Noto+Sans:wght@400;700&display=swap');

.summary-page-container {
  width: 100%;
  min-height: calc(100vh - 188px - 90px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;

  p {
    width: 100%;
    text-align: start;
    margin: 0;
    font-family: 'Noto sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
  }

  .inner {
    width: 100%;
    max-width: 1150px;
    padding: 0 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px;

      h1 {
        margin: 0;
      }

      .refresh-button {
        width: 90px;
        height: 40px;
        padding: 8px 15px;
        line-height: 22px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        background-color: #fff;
        transition: all 0.5s ease;
        text-align: center;
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
          transition: all 0.5s ease;
        }
      }
    }

    .stats-and-togglers {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      gap: 30px;
      padding: 10px;
      border: 1px solid #e8e8e8;
      border-radius: 4px;

      .stats {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
      }

      .togglers {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 4px;

        .toggler {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
          padding: 10px 0;

          p {
            text-align: end;
          }

          &:first-of-type {
            padding-top: 0;
          }

          &:last-of-type {
            padding-bottom: 0;
          }
        }
      }
    }

    .summary-list {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding: 10px 0;

      .user-item {
        width: 100%;
        max-width: 480px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 5px;
        border: 1px solid #e8e8e8;
        border-radius: 5px;

        .user-info {
          font-size: 20px;
          font-weight: 700;

          &.email {
            margin-bottom: 10px;
          }

          &.created-at {
            font-size: 16px;
            line-height: 100%;
            font-weight: 400;
          }

          @include lg {
            word-break: break-word;
            font-size: 16px;

            &.created-at {
              font-size: 14px;
            }
          }
        }

        .menu-list {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          h4 {
            font-size: 20px;
            font-weight: 600;
            width: 100%;
            margin: 20px 0 10px;
          }

          .subtitle-online + .menu-item {
            .alias-link {
              cursor: pointer;
            }
          }

          .menu-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            color: #212529;
            width: 100%;
            font-size: 16px;

            &:not(:last-of-type) {
              margin-bottom: 15px;
            }

            .alias-link {
              color: #212529;
              line-height: 20px;
              cursor: default;

              @include lg {
                width: 100%;
              }
            }

            .menu-name {
              line-height: 18px;
            }

            @include lg {
              word-break: break-word;
              font-size: 14px;
            }
          }
        }

        @include lg {
          width: 100%;
        }
      }
    }

    @include sm {
      padding: 0 10px;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
  }

  .switch-input {
    display: none;
  }

  .switch-label {
    display: block;
    width: 48px;
    height: 24px;
    padding: 0;
    background: transparent;
    border: none;
    text-indent: -150%;
    clip: rect(0 0 0 0);
    color: transparent;
    user-select: none;
  }

  .switch-label::before,
  .switch-label::after {
    content: '';
    display: block;
    position: absolute;
    cursor: pointer;
  }

  .switch-label::before {
    width: 100%;
    height: 100%;
    background-color: #dedede;
    border-radius: 9999em;
    -webkit-transition: background-color 0.25s ease;
    transition: background-color 0.25s ease;
  }

  .switch-label::after {
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
    -webkit-transition: left 0.25s ease;
    transition: left 0.25s ease;
  }

  .switch-input:checked + .switch-label::before {
    background-color: #89c12d;
  }

  .switch-input:checked + .switch-label::after {
    left: 24px;
  }
}
