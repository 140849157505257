.menu-page {
  padding-left: 20px;
  padding-right: 20px;

  @include lg {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;

    & + footer {
      display: none !important;

      .inner {
        display: none !important;
      }
    }
  }

  .inner {
    @include lg {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;

      .constructor-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.export-import {
  display: flex;
  position: fixed;
  width: 100%;
  height: 65px;
  padding: 10px 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: $color-gray-light;
  z-index: 16;
  min-width: 350px;
  left: 0;
  bottom: 0;

  .item {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 20px;

    &-icon {
      width: 15px;
      height: auto;
      margin: auto 5px 0;
    }

    .btn {
      font-size: 14px;
    }
  }

  @include lg {
    height: 58px;

    .item {
      margin: 0 10px;
    }
  }

  @include md {
    height: 50px;
  }
}
