header,
.v2-header {
  font-family: 'Noto sans', sans-serif;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .inner {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-link {
      .logo-image {
        width: 100%;
        min-width: 100%;
        height: auto;
      }
    }

    .menu {
      width: 100%;
      max-width: fit-content;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .lang-selector {
        outline: none;

        &::-ms-expand {
          display: none;
        }
      }
    }
  }

  @include md {
    .inner {
      max-width: 100%;
      padding: 20px 10px;
      gap: 20px;
    }
  }

  @include sm {
    height: fit-content;

    .inner {
      flex-direction: column;

      .menu {
        justify-content: center;
      }
    }
  }
}
