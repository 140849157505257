@mixin spacing($margin: 0, $padding: 0) {
  margin: $margin;
  padding: $padding;
}

@mixin xs {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}
