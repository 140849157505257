@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');

.viewer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @include lg {
    position: static;
  }

  .viewer {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include lg {
      position: static;
      width: 100%;
    }

    .export-import {
      display: flex;
      width: 407px;
      height: auto;
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .item {
        display: flex;
        flex-flow: row nowrap;
        margin: 0;

        &-icon {
          width: 15px;
          height: auto;
          margin: auto 5px 0;
        }
      }

      @include lg {
        display: none;
      }
    }

    .view-container {
      font-family: 'Noto Sans', sans-serif;
      position: relative;
      width: 407px;
      min-width: 350px;
      height: 787px;
      margin: 0;
      border: 16px black solid;
      border-top-width: 60px;
      border-bottom-width: 60px;
      border-radius: 36px;
      z-index: 11;
      transform-origin: top;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      @include lg {
        position: fixed;
        width: 100%;
        height: calc(100% - 58px);
        top: 0;
        left: 0;
        border: 0;
        border-radius: 0;
        margin: 0 auto;
        transform: translate(0, 0);
        transition: all 0.3s ease-in-out;
      }

      @include md {
        height: calc(100% - 50px);
      }

      &.hide {
        display: none;

        @include lg {
          transform: translate(-120vw, 0);
          transition: all 0.3s ease-in-out;
        }
      }

      &::before {
        content: '';
        display: block;
        width: 60px;
        height: 5px;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 10px;

        @include lg {
          display: none;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 35px;
        height: 35px;
        position: absolute;
        left: 50%;
        bottom: -65px;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 50%;

        @include lg {
          display: none;
        }
      }

      a {
        cursor: pointer;
      }

      .content {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
        background: transparent;
        flex-direction: column;
        display: flex;

        .header {
          display: flex;
          position: absolute;
          width: 100%;
          top: 0;

          .title {
            display: flex;
            padding: 5px 5px 0;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            height: fit-content;
            gap: 2px;

            h2 {
              font-size: 32px;
              line-height: 32px;
              font-style: normal;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 0.03em;
              word-break: break-all;
              margin-bottom: 0;
              cursor: pointer;
              height: fit-content;
            }

            .icon-arrows {
              cursor: pointer;
            }
          }

          .logo {
            display: flex;
            justify-content: center;
            align-items: center;

            &-img {
              position: absolute;
              top: 5px;
              width: 100px;
              height: 100px;
              object-fit: contain;
            }

            .logo-img-svg {
              position: absolute;
              top: 5px;
            }
          }
        }

        &-list {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          filter: none;

          h2,
          h3,
          h4,
          h5,
          p,
          .item-description {
            font-style: normal;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            margin: 0;
            transition: filter 0.4s ease-in-out;
          }

          .default-value-blur {
            filter: blur(3px);
            transition: filter 0.4s ease-in-out;
          }

          ul.categories {
            margin: 0;
            margin-bottom: 30px;
            padding: 0 10px;

            li.category {
              margin: 0;
              padding: 0;

              &:not(:first-of-type) {
                margin-top: 33px;
              }

              h2 {
                font-size: 24px;
                line-height: 33px;
                font-style: normal;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                margin: 0;
              }

              ul.subcategories {
                margin-top: 9px;

                li.subcategory {
                  margin-top: 23px;

                  &:first-child {
                    margin-top: 0;
                  }

                  h3 {
                    font-size: 20px;
                    line-height: 22px;
                    font-style: normal;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                    margin: 0;
                  }

                  ul.items {
                    margin-top: 20px;

                    .item {
                      display: flex;
                      flex-flow: row nowrap;
                      justify-content: space-between;
                      margin-top: 19px;
                      margin-left: 5px;

                      &:first-child {
                        margin-top: 0;
                      }

                      &-content {
                        // display: flex;
                        // flex-direction: column;
                        // justify-content: space-between;
                        // align-items: flex-start;
                        // flex-basis: content;
                        width: 60%;

                        .name {
                          h4 {
                            font-family: arial, helvetica, sans-serif;
                            font-size: 16px;
                            line-height: 16px;
                            font-weight: 400;
                            letter-spacing: 0.02em;
                          }
                        }

                        .description {
                          font-family: arial, helvetica, sans-serif;
                          font-size: 13px;
                          line-height: 14px;
                          font-weight: 400;
                          letter-spacing: 0.02em;
                          margin: 5px 0 0;
                          text-transform: none;
                          max-width: 80%;
                        }
                      }

                      .weight {
                        font-style: normal;
                        font-size: 14px;
                        line-height: 19px;
                        letter-spacing: 0.02em;
                        width: 20%;
                        text-align: end;
                      }

                      .price {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 19px;
                        letter-spacing: 0.02em;
                        width: 20%;
                        text-align: end;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .footer {
          position: relative;
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          .bg-mobilo {
            position: absolute;
            display: block;
            width: 100%;
            height: 50px;

            @include lg {
              display: none;
            }

            @include sm {
              display: block;
            }
          }

          .bg-desktop {
            position: absolute;
            display: none;
            width: 100%;
            height: 80px;

            @include lg {
              display: block;
            }

            @include md {
              height: 60px;
            }

            @include sm {
              display: none;
            }
          }

          &-inner {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 50px;

            .socials {
              width: 100%;
              max-width: 550px;
              height: 60px;
              padding: 0 20px;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              column-gap: 20px;
              row-gap: 6px;

              .footer-social-link {
                cursor: pointer;
              }
            }

            .powered-by {
              width: 100%;
              max-width: 1150px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;

              p,
              a {
                font-size: 14px;
                line-height: 125%;
              }
            }

            @include lg {
              margin-top: 80px;
            }

            @include md {
              margin-top: 60px;
            }

            @include sm {
              margin-top: 50px;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 0;
          display: none;
          background: transparent;

          &-track {
            display: none;
            background: transparent;
          }

          &-thumb {
            display: none;
            background-color: transparent;
            border: none;
          }
        }
      }
    }

    .skeleton-view-container {
      position: relative;
      height: 787px;
      width: 407px;

      span {
        height: 100%;
        width: 100%;
        border-radius: 36px;
      }
    }
  }
}
